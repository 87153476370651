import { Card, CardOwner } from '@/core/models';
import { cardsQuery } from '@/state/cards/cards.query';

export class Judge {
    canMoveCardToWaste(card: Card) {
        if (card.owner == CardOwner.stock) {
            return true;
        }

        if (card.owner != CardOwner.tableau) {
            return false;
        }

        const topWaste = cardsQuery.getTopByOwner(CardOwner.waste);
        if (!topWaste) {
            return true;
        }

        const rank = topWaste.rank;
        if (card.rank + 1 == rank || card.rank - 1 == rank) {
            return true;
        }

        if (rank == 13 && card.rank == 1) {
            return true;
        }

        return rank == 1 && card.rank == 13;
    }
}

export const judge = new Judge();
