import { CardDealerBaseController } from '@/core/card-dealer.base.controller';
import { cardsQuery } from '@/state/cards/cards.query';
import { CardOwner } from '@/core/models';
import { cardsService } from '@/state/cards/cards.service';
import { coreBus } from '@/core/core-bus';

export class DealerController extends CardDealerBaseController {
    constructor() {
        super(30, 35);
    }

    protected dealCard(i: number) {
        const card = cardsQuery.getTopByOwner(CardOwner.stock);
        if (!card) {
            throw new Error('no card in stock');
        }

        // tableau 1
        if (i >= 1 && i <= 5) {
            cardsService.update(card.id, {
                owner: CardOwner.tableau,
                ownerIndex: 1,
                order: i,
                isFaceUp: true,
                dragEnabled: i == 5,
            });
        }

        // tableau 2
        if (i >= 6 && i <= 10) {
            cardsService.update(card.id, {
                owner: CardOwner.tableau,
                ownerIndex: 2,
                order: i - 5,
                isFaceUp: true,
                dragEnabled: i == 10,
            });
        }

        // tableau 3
        if (i >= 11 && i <= 15) {
            cardsService.update(card.id, {
                owner: CardOwner.tableau,
                ownerIndex: 3,
                order: i - 10,
                isFaceUp: true,
                dragEnabled: i == 15,
            });
        }

        // tableau 4
        if (i >= 16 && i <= 20) {
            cardsService.update(card.id, {
                owner: CardOwner.tableau,
                ownerIndex: 4,
                order: i - 15,
                isFaceUp: true,
                dragEnabled: i == 20,
            });
        }

        // tableau 5
        if (i >= 21 && i <= 25) {
            cardsService.update(card.id, {
                owner: CardOwner.tableau,
                ownerIndex: 5,
                order: i - 20,
                isFaceUp: true,
                dragEnabled: i == 25,
            });
        }

        // tableau 6
        if (i >= 26 && i <= 30) {
            cardsService.update(card.id, {
                owner: CardOwner.tableau,
                ownerIndex: 6,
                order: i - 25,
                isFaceUp: true,
                dragEnabled: i == 30,
            });
        }

        // tableau 7
        if (i >= 31 && i <= 35) {
            cardsService.update(card.id, {
                owner: CardOwner.tableau,
                ownerIndex: 7,
                order: i - 30,
                isFaceUp: true,
                dragEnabled: i == 35,
            });
        }

        coreBus.cardMoveCmd$.next({
            duration: 0,
            cardId: card.id,
        });
    }
}
