import { coreBus } from '@/core/core-bus';
import { Card, CardOwner } from '@/core/models';
import { cardsQuery } from '@/state/cards/cards.query';
import { cardsService } from '@/state/cards/cards.service';
import { moveHistory } from '@/core/move-history';
import { gameService } from '@/state/game/game.service';
import { CardMoveBaseController } from '@/core/card-move-base.controller';
import { judge } from '@/games/golf/judge';
import { cardSound } from '@/core/sound';

export class MoveController extends CardMoveBaseController {
    constructor() {
        super({
            enableTableauAutoAdjust: false,
        });
        this.subscribeTo(coreBus.stockClickEvent$, () => {
            this.dealNextCard();
            coreBus.gameMoveCompletedEvent$.next();
        });
        this.subscribeTo(coreBus.cardClickEvent$, (ev) => {
            this.moveCardToWaste(ev.card);
            coreBus.gameMoveCompletedEvent$.next();
        });
    }

    private dealNextCard() {
        if (!this.canMakeMove()) {
            return;
        }

        const topStock = cardsQuery.getTopByOwner(CardOwner.stock);
        if (!topStock) {
            return;
        }

        const topWaste = cardsQuery.getTopByOwner(CardOwner.waste);
        const order = topWaste ? topWaste.order + 1 : 1;

        moveHistory.startMove();

        cardsService.update(topStock.id, {
            order,
            owner: CardOwner.waste,
            dragEnabled: false,
            isFaceUp: true,
        });
        moveHistory.addState(topStock);

        coreBus.cardMoveCmd$.next({
            duration: 300,
            cardId: topStock.id,
        });

        gameService.increaseMoveCounter();
        moveHistory.endMove();
        cardSound.playMove();
    }

    private moveCardToWaste(card: Card) {
        if (!this.canMakeMove()) {
            return;
        }

        if (!judge.canMoveCardToWaste(card)) {
            coreBus.shakeCardCmd$.next({
                card,
            });
            return;
        }

        const topWaste = cardsQuery.getTopByOwner(CardOwner.waste);
        const order = topWaste ? topWaste.order + 1 : 1;

        moveHistory.startMove();
        cardsService.update(card.id, {
            order,
            owner: CardOwner.waste,
            dragEnabled: false,
            isFaceUp: true,
        });
        moveHistory.addState(card);

        coreBus.cardMoveCmd$.next({
            duration: 300,
            cardId: card.id,
        });

        // update card bellow
        const topTab = cardsQuery.getTopByOwnerAndIndex(CardOwner.tableau, card.ownerIndex);
        if (topTab) {
            cardsService.update(topTab.id, {
                dragEnabled: true,
            });
            moveHistory.addState(topTab);
        }

        gameService.increaseMoveCounter();
        moveHistory.endMove();
        cardSound.playScore();
    }
}
