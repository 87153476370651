<template>
    <div>
        <empty-frame :style="tableau1Style"></empty-frame>
        <empty-frame :style="tableau2Style"></empty-frame>
        <empty-frame :style="tableau3Style"></empty-frame>
        <empty-frame :style="tableau4Style"></empty-frame>
        <empty-frame :style="tableau5Style"></empty-frame>
        <empty-frame :style="tableau6Style"></empty-frame>
        <empty-frame :style="tableau7Style"></empty-frame>

        <card-stock :style="stockStyle" :enable-recycle="false" />
        <empty-frame :style="wasteStyle"></empty-frame>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import EmptyFrame from '@/components/EmptyFrame.vue';
import { subscribeTo } from '@/core/rxjs-helpers';
import { gameQuery } from '@/state/game/game.query';
import { display } from '@/games/golf/display';
import { recomputable } from '@/composable/recomputable';
import { Position } from '@/core/models';
import CardStock from '@/components/CardStock.vue';

export default defineComponent({
    components: {
        EmptyFrame,
        CardStock,
    },

    setup() {
        const recompute = ref(0);

        subscribeTo(gameQuery.gameSize$, () => {
            setTimeout(() => {
                recompute.value += 1;
            });
        });

        const getFrameStyle = (pos: Position) => {
            return {
                top: `${pos.y}px`,
                left: `${pos.x}px`,
                width: `${display.cardSize.width}px`,
                height: `${display.cardSize.height}px`,
                borderRadius: `${display.cardSize.width * 0.06}px`,
            };
        };

        const tableau1Style = recomputable(recompute, () => {
            return getFrameStyle(display.tableauPosition[1]);
        });

        const tableau2Style = recomputable(recompute, () => {
            return getFrameStyle(display.tableauPosition[2]);
        });

        const tableau3Style = recomputable(recompute, () => {
            return getFrameStyle(display.tableauPosition[3]);
        });

        const tableau4Style = recomputable(recompute, () => {
            return getFrameStyle(display.tableauPosition[4]);
        });

        const tableau5Style = recomputable(recompute, () => {
            return getFrameStyle(display.tableauPosition[5]);
        });

        const tableau6Style = recomputable(recompute, () => {
            return getFrameStyle(display.tableauPosition[6]);
        });

        const tableau7Style = recomputable(recompute, () => {
            return getFrameStyle(display.tableauPosition[7]);
        });

        const stockStyle = recomputable(recompute, () => {
            return {
                top: `${display.stockPosition.y}px`,
                left: `${display.stockPosition.x}px`,
            };
        });

        const wasteStyle = recomputable(recompute, () => {
            return getFrameStyle(display.wastePosition);
        });

        return {
            tableau1Style,
            tableau2Style,
            tableau3Style,
            tableau4Style,
            tableau5Style,
            tableau6Style,
            tableau7Style,
            stockStyle,
            wasteStyle,
        };
    },
});
</script>

<style scoped></style>
