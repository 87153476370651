import { BaseGameController } from '@/core/base-game.controller';
import { Card, CardOwner, GameStatus, Suit } from '@/core/models';
import { range } from 'lodash';
import { cardsService } from '@/state/cards/cards.service';
import { cardsQuery } from '@/state/cards/cards.query';
import { gameQuery } from '@/state/game/game.query';
import { coreBus } from '@/core/core-bus';

export class GameController extends BaseGameController {
    constructor() {
        super();
        this.initStage();
        this.subscribeTo(gameQuery.gameStatus$, (status) => {
            if (status == GameStatus.dealCompleted) {
                if (process.env.NODE_ENV === 'development') {
                    // setTimeout(() => this.setCustomBoard(), 2000);
                }
            }
        });
    }

    private initStage() {
        const suits = [Suit.Diamond, Suit.Spade, Suit.Club, Suit.Heart];
        const cards = suits.flatMap((s) => {
            return range(1, 14).map(
                (r) =>
                    ({
                        id: `${r}${s}`,
                        suit: s,
                        rank: r,
                        order: 0,
                        dragEnabled: false,
                        owner: CardOwner.stock,
                    } as Card)
            );
        });
        cardsService.init(cards);
    }

    protected initGame(): void {
        cardsService.resetAllCards(CardOwner.stock);
    }

    protected isGameCompleted(): boolean {
        console.log('---1111');
        return cardsQuery.getAll().filter((c) => c.owner == CardOwner.tableau).length == 0;
    }

    private setCustomBoard() {
        const board: { owner: CardOwner; index: number; cards: string[] }[] = [
            {
                owner: CardOwner.tableau,
                index: 1,
                cards: ['10H', '11H'],
            },
            {
                owner: CardOwner.tableau,
                index: 2,
                cards: ['10C', '9C'],
            },
            {
                owner: CardOwner.waste,
                index: 1,
                cards: ['1C', '9C'],
            },
        ];

        const avails = cardsQuery.getAll().filter((card) => {
            for (let i = 0; i < board.length; i++) {
                if (board[i].cards.indexOf(card.id) >= 0) {
                    return false;
                }
            }
            return true;
        });

        cardsService.update(null, {
            owner: CardOwner.stock,
        });

        const updates = board.flatMap((item) => {
            return item.cards.flatMap((id, i) => {
                const card = id != '' ? cardsQuery.getEntity(id) : avails.pop();
                if (card) {
                    return [
                        {
                            // eslint-disable-next-line
                            ...card!,
                            isFaceUp: true,
                            dragEnabled: item.owner != CardOwner.stock,
                            owner: item.owner,
                            ownerIndex: item.index,
                            order: i + 1,
                        },
                    ];
                } else {
                    return [];
                }
            });
        });

        cardsService.upsertMany(updates);
        cardsService.updateTableauCount();
        cardsQuery.getAll().forEach((c) => {
            coreBus.cardMoveCmd$.next({
                cardId: c.id,
                duration: 0,
            });
        });
    }
}
