import { CardDragBaseController } from '@/core/card-drag.base.controller';
import { display } from '@/games/golf/display';
import { judge } from '@/games/golf/judge';
import { coreBus } from '@/core/core-bus';

export class DragController extends CardDragBaseController {
    constructor() {
        super({
            otherIntersect: [
                {
                    frames: () => [{ position: display.wastePosition, index: 1 }],
                    validate: (card, index) => judge.canMoveCardToWaste(card),
                    trigger: (card, index) => {
                        coreBus.cardClickEvent$.next({
                            card,
                            cardId: card.id,
                        });
                    },
                },
            ],
        });
    }
}
