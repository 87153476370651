import { CardAutoMoveBaseController } from '@/core/card-auto-move.base.controller';
import { Card, CardOwner, Hint } from '@/core/models';
import { cardsQuery } from '@/state/cards/cards.query';
import { judge } from '@/games/golf/judge';

export class AutoMoveController extends CardAutoMoveBaseController {
    protected doBestMove(card: Card) {
        // not needed, there is only one move
        // so it is in the move controller
    }

    protected isAutoFinishPossible(): boolean {
        return false;
    }

    protected doAutoFinish(done: () => void): void {
        done();
    }

    protected generateHints(): Hint[] {
        const tabTops = cardsQuery.getTopTableauCards();
        const topWaste = cardsQuery.getTopByOwner(CardOwner.waste);

        if (!topWaste) {
            return [];
        }

        const aaa = tabTops
            .filter((c) => judge.canMoveCardToWaste(c))
            .map((c) => ({
                card1Id: c.id,
                card2Id: topWaste.id,
                priority: 0,
            }));

        console.log('---hists', aaa);

        return aaa;
    }
}
